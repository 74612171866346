import React from "react";

import { Sections as SharedSections, getProperty } from "@lachevaliniere/shared";

import { usePages, useStaticChateau } from "hooks";

export const Sections = () => {
  const pages = usePages();
  const staticChateau = useStaticChateau();
  const sections = getProperty(staticChateau, "data.sections") || [];

  return <SharedSections sections={sections} pages={pages} noPaddingBottom={true} />;
};
