import React from "react";

import { MetaTags, getProperty } from "@lachevaliniere/shared";

import { useStaticChateau } from "hooks";

export const Meta = () => {
  const staticChateau = useStaticChateau();

  const title = getProperty(staticChateau, "data.metaTitle");
  const description = getProperty(staticChateau, "data.metaDescription");

  return <MetaTags title={title} description={description} />;
};
