import React, { Fragment } from "react";

import { Meta } from "./chateau/meta";
import { Gallery } from "./chateau/gallery";
import { Sections } from "./chateau/sections";
import { Impressions } from "./chateau/impressions";

export const Template = () => (
  <Fragment>
    <Meta />
    <Gallery />
    <Sections />
    <Impressions />
  </Fragment>
);

export default Template;
