import React, { useMemo } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

import { Section, Container, Text, Title, getProperty, arrayToObject } from "@lachevaliniere/shared";
import { useStaticChateau, usePages } from "hooks";

import styles from "./impressions.module.scss";

export const Impressions = () => {
  const pages = usePages();
  const staticChateau = useStaticChateau();
  const pagesById = useMemo(() => arrayToObject(pages), [pages]);

  const impressions = getProperty(staticChateau, "data.impressions") || [];

  return (
    <Section noPaddingBottom={true}>
      <Container>
        <div className={styles.container}>
          {impressions.map((impression, index) => {
            const title = getProperty(impression, "title");
            const text = getProperty(impression, "caption");
            const anchor = getProperty(impression, "anchor");
            const linkId = getProperty(impression, "link.0.id");
            const path = getProperty(pagesById, `${linkId}.data.path`);

            const fluid = getProperty(impression, "image.0.localFile.childImageSharp.fluid");

            return (
              <Link to={path} state={{ anchor }} key={index} className={styles.item}>
                <div className={styles.caption}>
                  <div className={styles.title}>
                    <Title wrapper="h3" noMargin={true} color="white">
                      {title}
                    </Title>
                  </div>
                  <div className={styles.text}>
                    <Text color="white">{text}</Text>
                  </div>
                </div>
                <div className={styles.image}>
                  <Img fluid={fluid} />
                </div>
              </Link>
            );
          })}
        </div>
      </Container>
    </Section>
  );
};
