import React, { useMemo } from "react";

import { Section, ContainerFromSM, ImageSlider, getProperty } from "@lachevaliniere/shared";
import { useStaticChateau } from "hooks";

export const Gallery = () => {
  const staticChateau = useStaticChateau();

  const gallery = getProperty(staticChateau, "data.gallery") || [];
  const slides = useMemo(() => gallery.map((item) => getProperty(item, "image.0.localFile")), [
    gallery,
  ]);
  const captions = useMemo(() => gallery.map((item) => getProperty(item, "caption")), [gallery]);

  return (
    <Section color="tertiary" noPaddingTop={true} noPaddingBottom={true}>
      <ContainerFromSM>
        <ImageSlider slides={slides} captions={captions} isPlaying={true} />
      </ContainerFromSM>
    </Section>
  );
};
